import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { fetchSelfCheckinPrint, printerLog } from '@/apis/aipass'
import dayjs from 'dayjs'
import html2canvas from 'html2canvas'
import QRCode from 'qrcode.react'
import uuid from 'react-uuid'
import { DeviceType } from '@/apis/vescajs'
import { PrinterContextCreator } from '@/contexts/printer'
import { errorHandler } from '@/libs/errors'

type Props = {
  hotelId: string
  checkinId: string
  paymentMethod: 'CREDIT_CARD' | 'DIRECT'
  payment:
    | {
        totalAmount: number
        paymentAmount: number
        totalAmountAt10Per: number
        totalAmountAt8Per: number
        totalTaxAt10Per: number
        totalTaxAt8Per: number
        receiptName: string | undefined
        brandName: string
        deviceType: DeviceType | undefined
      }
    | undefined
  onComplete: () => void
}

type TranslationStringType = {
  ja: string
  en: string
  zh: string
  ko: string
}

interface CheckinPrint {
  guestAppUrl: string
  guestName: string
  reservations: Array<{
    reservationId: string
    guestName: string
    paxOfAdults: number
    paxOfChildren: number
    checkinAt: string
    checkoutAt: string
    plan: TranslationStringType
    rooms: Array<{
      number: string
      key: string
    }>
    timeSelections: Array<{
      name: TranslationStringType
      selected: string[]
    }>
  }>
  hotel: {
    name: TranslationStringType
    postalCode: string | undefined
    address: TranslationStringType
    comment: TranslationStringType
    receiptRegistrationNumber: string
  }
  spaces: Array<{
    [reservationId: string]: Array<{ title: TranslationStringType; from: string }[]>
  }>
}

export const checkinEposPrinterIdStorage = 'checkinEposPrinterId'

export const CheckinReceipt: React.FC<Props> = props => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { eposPrinter, printer, connection } = useContext(PrinterContextCreator())
  const receiptDom = useRef<HTMLDivElement>(null)
  const [printCheckin, setPrintCheckin] = useState<CheckinPrint>()

  // 用紙サイズ 80mm=576px
  const paperSize = 576

  const printCheckInReceipt = async () => {
    try {
      setPrintCheckin(await fetchSelfCheckinPrint(props))
    } catch (e) {
      console.error(e)
      alert(t('SelfCheckIn.Failed to print'))
    }
  }

  const pickLanguage = (v: TranslationStringType) => v[language] || v.ja

  useEffect(() => {
    if (!receiptDom.current || !printCheckin) {
      return
    }
    html2canvas(receiptDom.current, {
      onclone: doc => {
        const div = doc.getElementById('print-content')
        div!.style.display = 'block'
      },
    })
      .then(async receiptImageCanvas => {
        try {
          await eposPrinter.printImage(receiptImageCanvas, props.checkinId, props.hotelId, printer)
          await printerLog({ message: 'success', ...props })
          props.onComplete()
        } catch (e) {
          await printerLog({ message: (e as any).message, ...props })
          console.error(e)
          try {
            eposPrinter.disconnect(connection, printer)
          } catch (e) {
            await printerLog({ message2: (e as any).message, ...props })
          }
          for (let retry = 0; retry < 100; retry++) {
            try {
              const oneTimeSession = await eposPrinter.initialize({
                hotelId: props.hotelId,
                connectIdKey: checkinEposPrinterIdStorage,
              })
              await eposPrinter.printImage(receiptImageCanvas, props.checkinId, props.hotelId, oneTimeSession.printer)
              props.onComplete()
              await printerLog({ message: 'success(retry)', ...props })
              return
            } catch {
              // no progress
            }
          }
          await printerLog({ message3: (e as any).message, ...props })
          throw e
        }
      })
      .catch(e => {
        console.error(e)
        errorHandler(e)
      })
  }, [receiptDom, printCheckin])

  useEffect(() => {
    printCheckInReceipt()
  }, [])

  if (!printCheckin) {
    return <></>
  }
  return (
    <div id="print-content" ref={receiptDom} css={[{ width: `calc(${paperSize}px / 2)` }, receiptStyle]}>
      <div className="accommodation-block">
        <h2>{t('SelfCheckIn.Accommodation')}</h2>
        <hr className="-normal" />
        {printCheckin.reservations.map(reservation => (
          <div key={uuid()}>
            <div>
              {reservation.rooms.map((room, i) => (
                <div className="room-block" key={i}>
                  <div>
                    <span className="label">{t('SelfCheckIn.Room')}</span>
                    <span className="value">{room.number}</span>
                  </div>
                  {room.key && (
                    <div>
                      <span className="label">{t('SelfCheckIn.Key')}</span>
                      <span className="value">{room.key}</span>
                    </div>
                  )}
                  {reservation.rooms.length !== i + 1 ? <hr className="-block" /> : <hr className="-normal" />}
                </div>
              ))}
            </div>
            <div className="reservation-block">
              <div className="list-item">
                <span className="label">{t('Your name')}</span>
                <span className="value">
                  {reservation.guestName} {t('SelfCheckIn.To')}
                </span>
              </div>
              <div className="list-item">
                <span className="label">{t('SelfCheckIn.No. of guests')}</span>
                <span className="value">
                  {t('Adult')}：{reservation.paxOfAdults}、{t('Child')}:{reservation.paxOfChildren}
                </span>
              </div>
              <div className="list-item">
                <span className="label">{t('SelfCheckIn.Period of stay')}</span>
                <span className="value">
                  {dayjs(reservation.checkinAt).format('YYYY/MM/DD')} ~ {dayjs(reservation.checkoutAt).format('YYYY/MM/DD')}
                </span>
              </div>
              {reservation.plan.ja && (
                <div className="list-item">
                  <span className="label">{t('Plan name')}</span>
                  <span className="value">{pickLanguage(reservation.plan)}</span>
                </div>
              )}
              {printCheckin.spaces[reservation.reservationId]?.map(ts => (
                <div className="list-item" key={uuid()}>
                  <span className="label">{pickLanguage(ts[0].title)}</span>
                  <span className="value">
                    {ts.map(({ from }) => (
                      <React.Fragment key={uuid()}>
                        {dayjs(from).format('MM/DD(ddd) HH:mm')}
                        <br />
                      </React.Fragment>
                    ))}
                  </span>
                </div>
              ))}
            </div>
            <hr className="-normal" />
          </div>
        ))}

        <div className="comment">{pickLanguage(printCheckin.hotel.comment)}</div>
      </div>

      <hr className="-block" />

      {props.payment && props.paymentMethod === 'CREDIT_CARD' && props.payment.paymentAmount > 0 && (
        <>
          <div className="payment-card-block">
            <h2>{t('Receipt')}</h2>
            <div className="stats-block">
              <div>
                {t('SelfCheckIn.Issue')} : {dayjs().format('YYYY/MM/DD')}{' '}
              </div>
              <div>
                {t('SelfCheckIn.Reg. no.')} : {printCheckin.hotel.receiptRegistrationNumber}
              </div>
            </div>
            <div className="detail-block">
              <div className="list-item-block">
                <div className="label">{t('address')}</div>
                <div>
                  {props.payment.receiptName || printCheckin.guestName}
                  <span style={{ paddingLeft: 16 }}>{t('SelfCheckIn.To')}</span>
                </div>
              </div>
              <hr className="-normal" />
              <div className="list-item-block">
                <div className="label">{t('Amount of money')}</div>
                <div>¥{props.payment.paymentAmount.toLocaleString()}</div>
              </div>
              <hr className="-normal" />
              <div className="breakdown-block">
                <div>
                  {t('SelfCheckIn.For 10%')}¥{props.payment.totalAmountAt10Per.toLocaleString()}({t('Tax')}¥
                  {props.payment.totalTaxAt10Per.toLocaleString()})
                </div>
                <div>
                  {t('SelfCheckIn.For 8%')}¥{props.payment.totalAmountAt8Per.toLocaleString()}({t('Tax')}¥
                  {props.payment.totalTaxAt8Per.toLocaleString()})
                </div>
              </div>
            </div>

            <div className="note-text">
              {props.payment.deviceType === DeviceType.PaidQr
                ? t('SelfCheckIn.Received by {{n}} for accommodation.', { n: props.payment.brandName })
                : t('SelfCheckIn.Received by credit card for accommodation.')}
            </div>
          </div>
          <hr className="-block" />
        </>
      )}

      {props.payment && props.paymentMethod === 'DIRECT' && props.payment.totalAmount > 0 && (
        <>
          <div className="payment-front-block">
            <h2>{t('SelfCheckIn.Details of use')}</h2>
            <div className="list-item-block">
              <div className="list-item">
                <span className="label">{t('SelfCheckinPayment.AmountUsed')}</span>
                <span className="value">¥{props.payment.totalAmount.toLocaleString()}</span>
              </div>
              <div className="list-item">
                <span className="label">{t('SelfCheckinPayment.PaymentOption')}</span>
                <span className="value">{t('SelfCheckinPayment.PayAtTheFrontDesk')}</span>
              </div>
            </div>
          </div>
          <hr className="-block" />
        </>
      )}

      <div className="guest-app-block">
        <div>
          <div className="title">
            <div>{t('SelfCheckIn.Information on being in the country can be found on your smartphone')}</div>
          </div>
          <hr className="-normal" style={{ margin: '12px 0' }} />
          <div className="icon-block">
            <div className="help-icon">
              <img src={require('@/static/images/icon_checkout_black.png')} />
              <span>{t('SelfCheckIn.Facility')}</span>
            </div>
            <div className="help-icon">
              <img src={require('@/static/images/icon_map_black.png')} />
              <span>{t('SelfCheckIn.Travel')}</span>
            </div>
            <div className="help-icon">
              <img src={require('@/static/images/icon_wifi_black.png')} />
              <span>Wi−Fi</span>
            </div>
          </div>
        </div>
        <QRCode value={printCheckin.guestAppUrl} size={64} />
      </div>

      <hr className="-block" />

      <div className="hotel-block">
        <div style={{ paddingBottom: 8 }}>
          <span>{pickLanguage(printCheckin.hotel.name)}</span>
        </div>
        {printCheckin.hotel.postalCode && (
          <div>
            <span>〒{printCheckin.hotel.postalCode}</span>
          </div>
        )}
        <div>
          <span>{pickLanguage(printCheckin.hotel.address)}</span>
        </div>
      </div>
    </div>
  )
}
const receiptStyle = css({
  display: 'none',
  fontFamily: 'Noto Sans JP',
  lineHeight: 1.5,
  '.accommodation-block': {
    padding: '24px 32px 0 32px',
    hr: { margin: '24px 0 !important' },
    '.room-block': {
      '>div': {
        display: 'flex',
        alignItems: 'center',
        color: '#272727',
        lineHeight: 1,
        paddingTop: 16,
        '&:first-of-type': { paddingTop: 0 },
        '.label': {
          width: 77,
          minWidth: 77,
          fontSize: 14,
          letterSpacing: 0.7,
        },
        '.value': {
          paddingLeft: 24,
          fontSize: 21,
          letterSpacing: 1.05,
        },
      },
    },
    '.reservation-block': {
      '.list-item': {
        '.label': {
          width: 60,
          minWidth: 60,
        },
      },
    },
    '.comment': {
      fontSize: 12,
      whiteSpace: 'break-spaces',
      color: '#272727',
    },
  },
  '.payment-card-block': {
    padding: '0 32px',
    color: '#272727',
    letterSpacing: 0.6,
    fontSize: 12,
    '.stats-block': { paddingTop: 24 },
    '.detail-block': {
      paddingTop: 32,
      '.list-item-block': {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: 14,
        '.label': { width: 50, minWidth: 50 },
      },
    },
    '.breakdown-block': { textAlign: 'right' },
    '.note-text': { paddingTop: 24 },
  },
  '.payment-front-block': {
    padding: '0 32px',
    '.list-item-block': { paddingTop: 24 },
  },
  '.guest-app-block': {
    padding: '0 32px',
    display: 'flex',
    gap: 16,
    alignItems: 'center',
    '>div:first-of-type': { flexGrow: 1 },
    '.title': {
      textAlign: 'center',
      fontSize: 12,
      letterSpacing: 0.8,
      color: '#272727',
    },
    '.icon-block': {
      display: 'flex',
      gap: 12,
      justifyContent: 'center',
      marginTop: 16,
      '.help-icon': {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        fontSize: 9,
        letterSpacing: 0.6,
        color: '#272727',
        alignItems: 'center',
        width: 43,
        'svg, img': { height: 18, width: 18 },
        span: { paddingTop: 4, fontSize: 9 },
      },
    },
  },
  '.hotel-block': {
    padding: '0 32px 24px 32px',
    marginTop: -16,
    div: {
      fontSize: 12,
      letterSpacing: 0.6,
      color: '#272727',
    },
  },

  '.list-item': {
    display: 'flex',
    alignItems: 'top',
    color: '#272727',
    lineHeight: 1.2,
    paddingTop: 12,
    '&:first-of-type': { paddingTop: 0 },
    '.label': {
      width: 77,
      minWidth: 77,
      fontSize: 12,
      letterSpacing: 0.6,
    },
    '.value': {
      '::before': { content: '":"', paddingRight: 8 },
      fontSize: 12,
      letterSpacing: 0.6,
      textIndent: '-1em',
      paddingLeft: '1em',
    },
  },

  h2: {
    textAlign: 'center',
    fontSize: 16,
  },
  hr: {
    '&.-normal': { borderTop: '2px solid #272727' },
    '&.-block': {
      borderTop: '2px dashed #A3A3A3',
      margin: '40px 0',
    },
  },
})
