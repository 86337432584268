import { EposPrinter, useEposPrinter } from '@/hooks/use-epos-printer'
import React, { Context, createContext, useState } from 'react'

type ContextType = {
  eposPrinter: EposPrinter
  connection: any
  setConnection: React.Dispatch<React.SetStateAction<any>>
  printer: any
  setPrinter: React.Dispatch<React.SetStateAction<any>>
  isConnected: () => boolean
}
const printerContext = createContext<ContextType | undefined>(undefined)

export const PrinterContextCreator = (): Context<ContextType> => {
  if (!printerContext) {
    throw new Error('undefined context')
  }
  return printerContext as Context<ContextType>
}

export const PrinterProvider = ({ children }) => {
  const eposPrinter = useEposPrinter()
  const [connection, setConnection] = useState<any>()
  const [printer, setPrinter] = useState<any>()

  const isConnected = () => Boolean(connection?.isConnected() && printer)

  return (
    <printerContext.Provider
      value={{
        eposPrinter,
        connection,
        setConnection,
        printer,
        setPrinter,
        isConnected,
      }}
    >
      {children}
    </printerContext.Provider>
  )
}
