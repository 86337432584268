import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import { FrontSupport } from '@/components/organisms/self-checkin/complete/front-support'
import { FrontSupportWithPrinter } from '@/components/organisms/self-checkin/complete/front-support-with-printer'
import { SelfCheckinBasicInfoPluginType } from '@/models/self-checkin/results'
import { AssignRoomWithPrinter } from '@/components/organisms/self-checkin/complete/assign-room-with-printer'
import { AssignRoom } from '@/components/organisms/self-checkin/complete/assign-room'
import { RoomInformationType } from '@/hooks/use-assign-key'
import { selfCheckinFetchGuestRoomAssign } from '@/apis/aipass'
import { IssueKeyWithPrinter } from '@/components/organisms/self-checkin/complete/issue-key-with-printer'
import { serialNumberStorageKey } from '@/models/self-checkin/card-reader'
import { useSelfCheckInState } from '@/hooks/use-self-check-in-state'
import { DeviceType as VescaDeviceType } from '@/apis/vescajs'
import { SelfCheckinLayout } from '@/components/layouts/self-checkin-layout'
import { checkinEposPrinterIdStorage } from '@/components/organisms/self-checkin/complete/checkin-receipt'

export type SelfCheckinCompleteState = {
  hotelId: string
  checkinId: string
  isFrontDescGuidance?: boolean
  roomInformation?: RoomInformationType[]
  basicInfo: SelfCheckinBasicInfoPluginType['basicInfo']
  paymentMethod: 'CREDIT_CARD' | 'DIRECT'
  payment?: {
    totalAmount: number
    paymentAmount: number
    totalAmountAt10Per: number
    totalAmountAt8Per: number
    totalTaxAt10Per: number
    totalTaxAt8Per: number
    receiptName: string | undefined
    brandName: string
    deviceType: VescaDeviceType | undefined
  }
}

export const SelfCheckinComplete: React.FC<{}> = () => {
  const history = useHistory()
  const { state } = useSelfCheckInState()
  const [eventListeners, setEventListeners] = useState<Array<{ type: keyof WindowEventMap; listener: EventListenerOrEventListenerObject }>>(
    [],
  )
  const [isPageInit, setIsPageInit] = useState<boolean>(false)
  const [existsUndefinedKeyRoom, setExistsUndefinedKeyRoom] = useState<boolean>(false)
  const isSettingCardReader = !!localStorage.getItem(serialNumberStorageKey)
  const existsPrinter: boolean = !!localStorage.getItem(checkinEposPrinterIdStorage)

  const goToTopPage = () => {
    history.push({
      pathname: `/self-checkin`,
      search: `?hotelId=${state.hotelId}`,
    })
  }

  const onClickTop = () => {
    eventListeners.map(eventListener => window.removeEventListener(eventListener.type, eventListener.listener))
    goToTopPage()
  }

  const initPageStatus = async () => {
    const roomInfoRes = await selfCheckinFetchGuestRoomAssign({
      hotelId: state.hotelId,
      checkinId: state.complete!.checkinId,
    })
    const flatRoomInfo: any = Object.values(roomInfoRes.rooms).flat()
    setExistsUndefinedKeyRoom(flatRoomInfo.some(room => !room.keyNumber || room.keyNumber === '-'))

    setIsPageInit(true)
  }

  const hasUnpaid: boolean = useMemo(() => {
    return state.complete!.paymentMethod !== 'CREDIT_CARD' && (state.confirm!.payment?.usage.totalAmount || 0) > 0
  }, [])

  useEffect(() => {
    window.addEventListener('popstate', goToTopPage, { once: true })
    setEventListeners([...eventListeners, { type: 'popstate', listener: goToTopPage }])
  }, [])

  useEffect(() => {
    const timeId = setTimeout(() => goToTopPage(), 60000)
    return () => clearTimeout(timeId)
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
    initPageStatus()
  }, [])

  if (!isPageInit) {
    return <></>
  }

  if (existsPrinter) {
    return (
      <SelfCheckinLayout>
        <div css={containerStyle}>
          {!hasUnpaid && state.complete!.roomInformation?.length && !existsUndefinedKeyRoom ? (
            <AssignRoomWithPrinter
              onClickTop={onClickTop}
              hotelId={state.hotelId}
              checkinId={state.complete!.checkinId}
              basicInfo={state.setting.basicInfo}
              state={state.complete!}
            />
          ) : !hasUnpaid && isSettingCardReader ? (
            <IssueKeyWithPrinter
              onClickTop={onClickTop}
              hotelId={state.hotelId}
              checkinId={state.complete!.checkinId}
              basicInfo={state.setting.basicInfo}
              state={state.complete!}
            />
          ) : (
            <FrontSupportWithPrinter onClickTop={onClickTop} state={state.complete!} />
          )}
        </div>
      </SelfCheckinLayout>
    )
  }
  return (
    <SelfCheckinLayout>
      <div css={containerStyle}>
        {!state.setting.accommodation.isFrontDescGuidance || state.complete!.roomInformation?.length ? (
          <AssignRoom
            onClickTop={onClickTop}
            hotelId={state.hotelId}
            checkinId={state.complete!.checkinId}
            basicInfo={state.setting.basicInfo}
          />
        ) : (
          <FrontSupport onClickTop={onClickTop} />
        )}
      </div>
    </SelfCheckinLayout>
  )
}

const containerStyle = css({
  width: '100%',
  position: 'relative',
  '*': {
    fontFamily: 'Noto Sans JP',
  },
})
