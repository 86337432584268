import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import {
  NOW,
  SpaceDetail,
  TIME_LINE_WIDTH,
  UNIT_NUMBER,
  convertDayJsFromString,
  convertEndTime,
  getTime,
  getTimeRangeCount,
  isOverlapOtherTimes,
} from '@/models/space-manager/timeline'
import uuid from 'react-uuid'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

export const times: string[] = []
for (let hour = 0; hour < 24; hour++) {
  const time = dayjs().startOf('day').add(hour, 'hour').format('HH:mm')
  times.push(time)
}

interface TimelineProps {
  spaceIndex: number
  spaceId: string
  typeName: string
  salesHour: { start: string; end: string }
  usageTime: { start: string | undefined; end: string | undefined } | undefined
  addSpace: (space: SpaceDetail, allowEdit?: boolean) => void
  editingTimelineSpaces: SpaceDetail[] | undefined
  selectedTimelineSpaces: SpaceDetail[] | undefined
  allReservedSpaces: SpaceDetail[]
  editMode: boolean
  removeEditReservation: (removeSpace: SpaceDetail) => void
  onClickSpaceInTimeline: (reservationId?: string) => void
}
export const Timeline: React.FC<TimelineProps> = ({
  spaceId,
  spaceIndex,
  typeName,
  addSpace,
  salesHour,
  usageTime,
  editingTimelineSpaces,
  selectedTimelineSpaces,
  allReservedSpaces,
  editMode,
  removeEditReservation,
  onClickSpaceInTimeline,
}) => {
  const usageUnitCount = useMemo(() => {
    if (!usageTime || !usageTime.start) {
      return 60
    }

    const min = dayjs(NOW.format(`YYYY/MM/DD 00:00`))
    const end = dayjs(NOW.format(`YYYY/MM/DD ${usageTime.start}`))
    return end.diff(min, 'minute')
  }, [usageTime])

  const onAddSpace = (e: React.MouseEvent<HTMLSpanElement>) => {
    const unitItem = e.currentTarget

    const startTime = unitItem.dataset.start!
    const startTimeDayJs = convertDayJsFromString(startTime)
    const endTimeDayJs = startTimeDayJs.add(usageUnitCount, 'minute')

    const isOverlap = isOverlapOtherTimes({
      newStartTime: startTimeDayJs,
      newEndTime: endTimeDayJs,
      targetSpaces: editingTimelineSpaces?.filter(selectedSpace => selectedSpace.spaceIndex === spaceIndex) || [],
      salesHour,
    })
    if (isOverlap) {
      return
    }

    const sameTimes = editingTimelineSpaces?.find(
      space =>
        startTimeDayJs.isSameOrAfter(convertDayJsFromString(space.usageTime.usageFrom)) &&
        startTimeDayJs.isBefore(convertDayJsFromString(space.usageTime.usageTo)),
    )
    const endTime = endTimeDayJs.format('HH:mm')
    const newTimes = {
      usageFrom: sameTimes ? sameTimes.usageTime.usageFrom : startTime,
      usageTo: sameTimes ? sameTimes.usageTime.usageTo : convertEndTime(endTime),
    }

    const rangeUnitCount = getTimeRangeCount({ start: newTimes.usageFrom, end: newTimes.usageTo })
    const width = unitItem.clientWidth * rangeUnitCount
    const { hour, minute } = getTime(newTimes.usageFrom)
    const left = hour * TIME_LINE_WIDTH + (minute / 60) * TIME_LINE_WIDTH

    addSpace(
      {
        spaceTypeName: typeName,
        spaceIndex,
        salesHour,
        usageTime: newTimes,
        pax: 1,
        answer: '',
        reservationUserName: sameTimes?.reservationUserName || '',
        reservation: {
          id: '',
          reservationId: '',
          approvedStatus: 0,
          checkinId: '',
          guestName: '',
        },
        spaceId,
        groupId: sameTimes ? sameTimes.groupId : uuid(),
        style: { left, width, backgroundColor: '#F2A40B', color: '#fff' },
      },
      true,
    )
  }

  const isInSalesHour = (props: { startTime: dayjs.Dayjs; endTime: dayjs.Dayjs }) => {
    const saleStart = convertDayJsFromString(salesHour.start)
    const salesEnd = convertDayJsFromString(salesHour.end)

    return props.startTime.isSameOrAfter(saleStart) && props.endTime.isSameOrBefore(salesEnd)
  }

  const timelines = () => {
    const unit = 60 / UNIT_NUMBER // by 15 15Minute

    const spans: JSX.Element[] = []
    times.forEach(timeSlot => {
      const { hour } = getTime(timeSlot)
      for (let i = 0; i < unit; i++) {
        const unitStartTime = convertDayJsFromString(`${hour}:${(i * UNIT_NUMBER).toString().padStart(2, '0')}`)
        const unitEndTime = unitStartTime.add(UNIT_NUMBER, 'minute')

        const isInSales = isInSalesHour({ startTime: unitStartTime, endTime: unitEndTime })

        spans.push(
          <span
            data-start={unitStartTime.format('HH:mm')}
            data-end={unitEndTime.format('HH:mm')}
            key={`${timeSlot}-${i}`}
            className={`unit ${!isInSales ? 'overtime' : ''}`}
            style={{ borderStyle: i < unit - 1 ? 'dashed' : 'solid' }}
            onClick={e => onAddSpace(e)}
          />,
        )
      }
    })
    return spans
  }

  const AssignBar = ({ spaceDetail, onClick, showDelete }: { spaceDetail: SpaceDetail; onClick?: () => void; showDelete?: boolean }) => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingLeft: 8,
          position: 'absolute',
          top: 0,
          bottom: 0,
          margin: 'auto',
          cursor: 'pointer',
          left: `${spaceDetail.style.left + 5}px`,
          width: `${spaceDetail.style.width - 10}px`,
          height: 32,
          right: 'auto',
          color: spaceDetail.style.color,
          borderRadius: 5,
          fontSize: 12,
          fontWeight: 'bold',
          background: spaceDetail.style.backgroundColor,
          border: spaceDetail.style.border ? spaceDetail.style.border : 'none',
        }}
        onClick={onClick}
      >
        <span style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>{spaceDetail.reservationUserName}</span>
        {showDelete && editMode && (
          <span
            style={{ cursor: 'pointer', marginLeft: 'auto' }}
            onClick={e => {
              e.stopPropagation()
              removeEditReservation(spaceDetail)
            }}
          >
            <img src={require('@/static/images/delete_white.svg')} />
          </span>
        )}
      </div>
    )
  }

  const newAddSpaces = editingTimelineSpaces?.filter(toAddSpace => toAddSpace.spaceIndex === spaceIndex)
  const spaceIdOfSelectedTimeline = selectedTimelineSpaces?.map(s => s.id).filter(s => !!s) as string[]
  const excludeEditReservedSpaces = allReservedSpaces.filter(
    // 選択中の予約なしまたは選択中のときはその予約外のスペースをその他スペースとする
    reservedSpace => !spaceIdOfSelectedTimeline.length || (reservedSpace.id && !spaceIdOfSelectedTimeline.includes(reservedSpace.id)),
  )

  return (
    <>
      {timelines()}
      {newAddSpaces?.map(space => <AssignBar showDelete key={uuid()} spaceDetail={space} onClick={() => onClickSpaceInTimeline()} />)}
      {excludeEditReservedSpaces.map(reservedSpace => (
        <AssignBar
          key={uuid()}
          spaceDetail={reservedSpace}
          onClick={() => onClickSpaceInTimeline(reservedSpace.reservation.reservationId)}
        />
      ))}
    </>
  )
}
