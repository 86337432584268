import React, { useEffect, useMemo, useState } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { SINGLE_CHOICE, MULTIPLE_CHOICE } from '@/constants/type-select-reservations'
import { SelfCheckinHeader } from '@/components/molecules/self-checkin/header'
import { SelfCheckinFooter } from '@/components/molecules/self-checkin/footer'
import { ReservationDetail } from '../../organisms/self-checkin/select-reservation/reservation-detail'
import { ErrorNotice } from '../../organisms/self-checkin/select-reservation/error-notice'
import { useHistory } from 'react-router-dom'
import { selfCheckinGuestRoomAutoAssign } from '@/apis/aipass'
import { RadioGroupField } from '@/components/molecules/radio-group-field'
import { SearchReservationTypeWhenCheckedIn, SearchReservationTypeWhenNonCheckIn } from '@/models/reservation'
import { useSelfCheckInState } from '@/hooks/use-self-check-in-state'
import { SelfCheckinLayout } from '@/components/layouts/self-checkin-layout'

export const SelectReservation: React.FC<{}> = () => {
  const [selectReservationType, setSelectReservationType] = useState<number>(SINGLE_CHOICE)
  const [checkedList, setCheckedList] = useState<string[]>([])
  const [checkedSingleResvertion, setCheckedSingleResvertion] = useState<string>('')
  const [isDisabledSubmit, setIsDisabledSubmit] = useState<boolean>(false)
  const [isInitializeComponent, setIsInitializeComponent] = useState(false)
  const history = useHistory()
  const { t } = useTranslation()
  const isDisabled = (): boolean => {
    const checkDisabled =
      Boolean(
        (selectReservationType === MULTIPLE_CHOICE && checkedList.length === 0) ||
          (selectReservationType === SINGLE_CHOICE && !checkedSingleResvertion),
      ) || isDisabledSubmit
    return checkDisabled
  }
  const { state, saveState } = useSelfCheckInState()

  const isCheckedListReservation = item => checkedList.includes(item)

  const handleCheckListResvartion = (e, id) => {
    e.preventDefault()
    let updatedListReservations = [...checkedList]
    if (!isCheckedListReservation(id)) {
      updatedListReservations = [...checkedList, id]
    } else {
      updatedListReservations.splice(checkedList.indexOf(id), 1)
    }
    setCheckedList(updatedListReservations)
  }

  const submit = async () => {
    setIsDisabledSubmit(true)
    let selectedReservations: SearchReservationTypeWhenNonCheckIn[] | SearchReservationTypeWhenCheckedIn[]
    if (selectReservationType === MULTIPLE_CHOICE) {
      selectedReservations = state.searchReservation!.result.notCheckinReservations?.filter(r =>
        checkedList.includes(r.reservationId),
      ) as SearchReservationTypeWhenNonCheckIn[]
    } else {
      selectedReservations = state.searchReservation!.result.preCheckinReservations!.filter(r => checkedSingleResvertion === r.checkinId)
    }
    const selectedReservationIds = selectedReservations.map(r => r.reservationId)
    const checkedInReservation =
      selectReservationType === SINGLE_CHOICE ? (selectedReservations[0] as SearchReservationTypeWhenCheckedIn) : undefined

    selfCheckinGuestRoomAutoAssign({ hotelId: state.hotelId, reservationIds: selectedReservationIds })
    saveState({
      ...state,
      selectReservation: {
        hasSmartCheckin: selectReservationType === SINGLE_CHOICE,
        smartCheckinId: checkedInReservation?.checkinId,
        selectReservationType,
        selectedReservationIds,
        selectedReservations,
        paxTotal: checkedInReservation?.accompany
          ? checkedInReservation.accompany.length + 1
          : selectedReservations.map(r => r.paxTotal).reduce((pre, curr) => pre + curr, 0),
      },
    })
    history.push({ pathname: '/self-checkin/accommodation-info' })
  }

  const handleChangeSelectReservationType = e => {
    setSelectReservationType(parseInt(e.target.value))
  }

  const handleCheckSingleReservation = id => {
    setCheckedSingleResvertion(id)
  }

  const computedViewReservations = (): SearchReservationTypeWhenNonCheckIn[] | SearchReservationTypeWhenCheckedIn[][] | undefined => {
    return selectReservationType === MULTIPLE_CHOICE
      ? state.searchReservation?.result.notCheckinReservations
      : preCheckinReservationsByCheckin
  }
  const preCheckinReservationsByCheckin = useMemo(() => {
    if (!state.searchReservation?.result.preCheckinReservations?.length) {
      return []
    }
    const checkinGroup: { [checkinId: string]: SearchReservationTypeWhenCheckedIn[] } = {}
    state.searchReservation?.result.preCheckinReservations.forEach(r => {
      if (!checkinGroup[r.checkinId]) {
        checkinGroup[r.checkinId] = []
      }
      checkinGroup[r.checkinId].push(r)
    })
    return Object.values(checkinGroup)
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
    if (!state?.hotelId) {
      history.replace({ pathname: '/dashboard' })
      return
    }
    if (!state.searchReservation) {
      history.replace({ pathname: `/self-checkin?hotelId=${state.hotelId}` })
      return
    }
    setIsInitializeComponent(true)

    if (state.selectReservation?.selectReservationType) {
      // 選択内容がstate
      setSelectReservationType(state.selectReservation.selectReservationType)
      if (state.selectReservation.selectReservationType === SINGLE_CHOICE) {
        const findSelectCheckIn = state.searchReservation.result.preCheckinReservations?.find(
          reservation => state.selectReservation?.selectedReservationIds?.includes(reservation.reservationId),
        )
        if (findSelectCheckIn) {
          setCheckedSingleResvertion(findSelectCheckIn.checkinId)
        }
      } else {
        setCheckedList(state.selectReservation.selectedReservationIds)
      }
    } else {
      if (state.searchReservation.result.preCheckinReservations) {
        setCheckedSingleResvertion(state.searchReservation.result.preCheckinReservations[0].checkinId || '')
        setSelectReservationType(SINGLE_CHOICE)
      } else if (state.searchReservation.result.notCheckinReservations && state.searchReservation.result.notCheckinReservations.length) {
        setCheckedList(state.searchReservation.result.notCheckinReservations.map(r => r.reservationId))
        setSelectReservationType(MULTIPLE_CHOICE)
      }
    }
  }, [])

  if (!isInitializeComponent) {
    return <></>
  }
  return (
    <SelfCheckinLayout>
      <div css={containerStyle}>
        <SelfCheckinHeader goToPreviousPage={history.goBack} title={t('Select reservation')} />
        <div css={mainStyle}>
          <p className="subtitle">{t('Please select the reservation')}</p>
          <div css={selectReservationRadio}>
            <RadioGroupField
              value={selectReservationType}
              items={[
                {
                  value: MULTIPLE_CHOICE,
                  label: t('Reservations without advance check-in'),
                },
                {
                  value: SINGLE_CHOICE,
                  label: t('Reservations with advance check-in'),
                },
              ]}
              style={{ radioSize: 35, direction: 'column' }}
              itemWrapperCss={selectReservationItem}
              itemCss={radioButtonStyle}
              onChange={handleChangeSelectReservationType}
            />
          </div>

          <div css={wrapperStyle}>
            {computedViewReservations()?.length ? (
              computedViewReservations()!.map((reservation, index) => (
                <div css={reservationWrapperStyle} key={index}>
                  <ReservationDetail
                    reservation={reservation}
                    selectType={selectReservationType}
                    handleCheckListResvartion={handleCheckListResvartion}
                    isCheckedListReservation={isCheckedListReservation}
                    checkedSingleResvertion={checkedSingleResvertion}
                    handleCheckSingleReservation={handleCheckSingleReservation}
                  />
                </div>
              ))
            ) : (
              <div css={errorWrapperStyle}>
                <ErrorNotice />
              </div>
            )}
          </div>
        </div>
        <SelfCheckinFooter isDisabled={isDisabled} goToNextPage={submit} isNext={'next'} />
      </div>
    </SelfCheckinLayout>
  )
}

const containerStyle = css({
  width: '100%',
  position: 'relative',
  backgroundColor: '#F2F2F2',
  '*': {
    fontFamily: 'Noto Sans JP',
    color: '#272727',
  },
})

const mainStyle = css({
  padding: '102px 0 117px ',
  minHeight: '100vh',

  '.subtitle': {
    textAlign: 'center',
    lineHeight: '31px',
    letterSpacing: '3.15px',
    fontSize: '21px',
    fontWeight: 'bold',
    marginBottom: '24px',
  },
})
const selectReservationRadio = css({
  marginTop: '24px',
  marginBottom: '32px',
  width: '100%',
})

const selectReservationItem = css({
  display: 'flex',
  height: '59px',
  backgroundColor: '#fff',
  '&:first-of-type': {
    borderBottom: '1px solid #f2f2f2',
  },
})

const radioButtonStyle = css({
  width: '100%',
  margin: '0 auto',
  maxWidth: 872,
  label: {
    color: '#272727',
    fontWeight: 'bold',
    fontSize: '17px',
    letterSpacing: '1.7px',
  },
})

const reservationWrapperStyle = css({
  maxWidth: 872,
  margin: '32px auto 0',
  border: '1px solid #ccc',
  borderRadius: '5px',
})

const wrapperStyle = css({
  padding: '0 77px 0 75px',
})
const errorWrapperStyle = css({
  maxWidth: 872,
  margin: '32px auto 0',
  border: '1px solid #ccc',
  borderRadius: '5px',
})
