import React, { useState, useEffect, useContext } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { SelfCheckinHeader } from '@/components/molecules/self-checkin/header'
import { SelfCheckinFooter } from '@/components/molecules/self-checkin/footer'
import { CurrentVersionKey, LatestVersionKey, fetchReservationsForSelfCheckin } from '@/apis/aipass'
import { SearchReservationTypeWhenCheckedIn, SearchReservationTypeWhenNonCheckIn } from '@/models/reservation'
import { LoaderContextCreator } from '@/contexts/loader'
import { useSelfCheckInState } from '@/hooks/use-self-check-in-state'
import { hasAnyReservationPlugin } from '@/libs/plugins'
import { SelfCheckinLayout } from '@/components/layouts/self-checkin-layout'

export const SearchReservation: React.FC<{}> = () => {
  const {
    t,
    i18n: { language: lang },
  } = useTranslation()
  const { setIsLoading } = useContext(LoaderContextCreator())
  const [inputType, setInputType] = useState<'phone' | 'reserId' | 'name' | 'furigana' | 'qr'>('phone')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [reservationId, setReservationId] = useState<string>('')
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [firstNameFurigana, setFirstNameFurigana] = useState<string>('')
  const [isDisabledSubmit, setIsDisabledSubmit] = useState<boolean>(false)
  const [lastNameFurigana, setLastNameFurigana] = useState<string>('')
  const [isInitializeComponent, setIsInitializeComponent] = useState(false)
  const [isDuplicateReservationBySameName, setIsDuplicateReservationBySameName] = useState(false)
  const history = useHistory()
  const typeSubmit = inputType === 'qr' ? 'next' : 'search'
  const { state, saveState } = useSelfCheckInState()
  const locateInfo = useLocation<{ hotelId: string }>()

  const isDisabled = (): boolean => {
    let checkValueInput

    switch (inputType) {
      case 'phone':
        checkValueInput = Boolean(phoneNumber.trim())
        break
      case 'reserId':
        checkValueInput = Boolean(reservationId.trim())
        break
      case 'name':
        checkValueInput = isDuplicateReservationBySameName ? Boolean(phoneNumber.trim()) : Boolean(firstName.trim() && lastName.trim())
        break
      case 'furigana':
        checkValueInput = isDuplicateReservationBySameName
          ? Boolean(phoneNumber.trim())
          : Boolean(firstNameFurigana.trim() && lastNameFurigana.trim())
        break
      case 'qr':
        checkValueInput = Boolean(inputType === 'qr')
        break
      default:
        checkValueInput = false
    }
    return !checkValueInput
  }

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    if (name === 'phoneNumber') setPhoneNumber(value)
    if (name === 'reservationId') setReservationId(value)
    if (name === 'firstName') setFirstName(value)
    if (name === 'lastName') setLastName(value)
    if (name === 'firstNameFurigana') setFirstNameFurigana(value)
    if (name === 'lastNameFurigana') setLastNameFurigana(value)
  }

  const switchButton = (inputType: 'phone' | 'reserId' | 'name' | 'furigana' | 'qr') => {
    setInputType(inputType)
    setPhoneNumber('')
    setReservationId('')
    setFirstName('')
    setLastName('')
    setFirstNameFurigana('')
    setLastNameFurigana('')
  }

  const checkHasDuplicateReservationBySameName = (searchedReservations: SearchReservationTypeWhenNonCheckIn[] | undefined) => {
    if (!searchedReservations) return

    const result = searchedReservations.some((searchedReservation, _, reservations) => {
      return reservations.some(
        reservation =>
          (reservation.guestName === searchedReservation.guestName ||
            reservation.userName === searchedReservation.userName ||
            reservation.guestNameKana === searchedReservation.guestNameKana ||
            reservation.userNameKana === searchedReservation.userNameKana) &&
          (reservation.guestTel !== searchedReservation.guestTel ||
            reservation.guestMailAddr !== searchedReservation.guestMailAddr ||
            reservation.guestAddr !== searchedReservation.guestAddr),
      )
    })
    return result
  }

  const submit = async () => {
    if (inputType === 'qr') {
      history.push({ pathname: '/self-checkin/qr-checkin-camera' })
      return
    }
    const params: {
      hotelId: string
      reservationTel?: string
      reservationId?: string
      reservationFamilyName?: string
      reservationGivenName?: string
      reserFuriganaFamilyName?: string
      reserFuriganaGivenName?: string
    } = { hotelId: state.hotelId }
    switch (inputType) {
      case 'phone':
        params.reservationTel = phoneNumber
        break
      case 'reserId':
        params.reservationId = reservationId
        break
      case 'name':
        params.reservationFamilyName = lastName
        params.reservationGivenName = firstName
        params.reservationTel = isDuplicateReservationBySameName ? phoneNumber : ''
        break
      case 'furigana':
        params.reserFuriganaFamilyName = lastNameFurigana
        params.reserFuriganaGivenName = firstNameFurigana
        params.reservationTel = isDuplicateReservationBySameName ? phoneNumber : ''
        break
    }

    setIsDisabledSubmit(true)

    setIsLoading(true)
    const [notCheckinReservationFetchResponse, preCheckinReservationFetchResponse] = await Promise.all([
      fetchReservationsForSelfCheckin({ ...params, isCheckin: 0 }),
      fetchReservationsForSelfCheckin({ ...params, isCheckin: 1 }),
    ])
    setIsLoading(false)

    if (!notCheckinReservationFetchResponse && !preCheckinReservationFetchResponse) {
      alert(t('There is no reservation information'))
      setIsDisabledSubmit(false)
      return
    }
    const notCheckinReservations = notCheckinReservationFetchResponse?.searchReservations as SearchReservationTypeWhenNonCheckIn[]
    const preCheckinReservations = preCheckinReservationFetchResponse?.searchReservations as SearchReservationTypeWhenCheckedIn[]

    const doCheckDuplication = !isDuplicateReservationBySameName && (inputType === 'name' || inputType === 'furigana')
    if (doCheckDuplication) {
      if (
        checkHasDuplicateReservationBySameName(notCheckinReservations) ||
        checkHasDuplicateReservationBySameName(preCheckinReservations)
      ) {
        setIsDuplicateReservationBySameName(true)
        setIsDisabledSubmit(false)
        return
      }
    }

    saveState({
      hotelId: state.hotelId,
      setting: state.setting,
      searchReservation: {
        condition: {
          inputType,
          phoneNumber,
          reservationId,
          firstName,
          lastName,
          firstNameFurigana,
          lastNameFurigana,
        },
        result: {
          isDuplicateReservationBySameName: false,
          notCheckinReservations,
          preCheckinReservations,
        },
      },
    })
    history.push({ pathname: '/self-checkin/select-reservation' })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    if (!state?.hotelId) {
      if (locateInfo.state?.hotelId) {
        history.replace({ pathname: '/self-checkin', search: `?hotelId=${locateInfo.state.hotelId}` })
        return
      }
      history.replace({ pathname: '/dashboard' })
      return
    }
    if (!hasAnyReservationPlugin(state.setting.plugin)) {
      history.replace('/self-checkin/accommodation-info')
      return
    }
    if (sessionStorage.getItem(CurrentVersionKey) !== sessionStorage.getItem(LatestVersionKey)) {
      sessionStorage.removeItem(CurrentVersionKey)
      sessionStorage.removeItem(LatestVersionKey)
      return window.location.reload()
    }
    setIsInitializeComponent(true)
    if (state && state.searchReservation) {
      switch (state.searchReservation.condition.inputType) {
        case 'phone':
          setInputType('phone')
          setPhoneNumber(state.searchReservation.condition.phoneNumber)
          break
        case 'reserId':
          setInputType('reserId')
          setReservationId(state.searchReservation.condition.reservationId)
          break
        case 'name':
          setInputType('name')
          setFirstName(state.searchReservation.condition.firstName)
          setLastName(state.searchReservation.condition.lastName)
          break
        case 'furigana':
          setInputType('furigana')
          setFirstNameFurigana(state.searchReservation.condition.firstNameFurigana)
          setLastNameFurigana(state.searchReservation.condition.lastNameFurigana)
          break
        case 'qr':
          setInputType('qr')
          break
        default:
          break
      }
    }
  }, [])

  if (!isInitializeComponent) {
    return <></>
  }
  return (
    <SelfCheckinLayout>
      <div css={containerStyle}>
        <SelfCheckinHeader goToPreviousPage={history.goBack} title={t('Search your reservation')} />
        <div css={mainStyle}>
          <p className="subtitle">
            {t(
              isDuplicateReservationBySameName
                ? 'Found a reservation with the same name.\nPlease enter your phone number.'
                : 'Please search by one of the following methods',
            )}
          </p>
          <div css={form}>
            {isDuplicateReservationBySameName ? (
              <div className="reservationLabel">
                <p style={{ fontWeight: 'bold', fontSize: 17 }}>{t('Phone number')}</p>
                <div css={[reservationTelForm, { marginLeft: 0 }]}>
                  <input
                    type="tel"
                    placeholder="08012345678"
                    pattern="^[0-9]+$"
                    name="phoneNumber"
                    value={phoneNumber}
                    onChange={onChangeValue}
                  />
                </div>
              </div>
            ) : (
              <>
                <div className="reservationLabel">
                  <div css={radioItemStyle} onClick={() => switchButton('phone')}>
                    <img src={require(`@/static/images/radio_${inputType === 'phone' ? 'on' : 'off'}.svg`)} css={radioStyle} />
                    <p>{t('The phone number of your reservation')}</p>
                  </div>
                  {inputType === 'phone' && (
                    <div css={reservationTelForm}>
                      <input
                        type="tel"
                        placeholder="08012345678"
                        pattern="^[0-9]+$"
                        name="phoneNumber"
                        value={phoneNumber}
                        onChange={onChangeValue}
                      />
                    </div>
                  )}
                </div>
                <div className="reservationLabel">
                  <div css={radioItemStyle} onClick={() => switchButton('reserId')}>
                    <img src={require(`@/static/images/radio_${inputType === 'reserId' ? 'on' : 'off'}.svg`)} css={radioStyle} />
                    <p>{t('Reservation ID')}</p>
                  </div>
                  {inputType === 'reserId' && (
                    <div css={reservationTelForm}>
                      <input
                        type="text"
                        placeholder="aipass001"
                        pattern="^[a-zA-Z0-9!-/:-@¥[-`{-~ ]*$"
                        name="reservationId"
                        value={reservationId}
                        onChange={onChangeValue}
                      />
                    </div>
                  )}
                </div>
                <div className="reservationLabel">
                  <div css={radioItemStyle} onClick={() => switchButton('name')}>
                    <img src={require(`@/static/images/radio_${inputType === 'name' ? 'on' : 'off'}.svg`)} css={radioStyle} />
                    <p>{t('Reservation name or guest name')}</p>
                  </div>
                  {inputType === 'name' && (
                    <div css={nameForm}>
                      <input
                        className="lastName"
                        type="text"
                        placeholder={t('Last name')}
                        name="lastName"
                        value={lastName}
                        onChange={onChangeValue}
                      />
                      <input type="text" placeholder={t('First name')} name="firstName" value={firstName} onChange={onChangeValue} />
                    </div>
                  )}
                </div>
                {lang === 'ja' && (
                  <div className="reservationLabel">
                    <div css={radioItemStyle} onClick={() => switchButton('furigana')}>
                      <img src={require(`@/static/images/radio_${inputType === 'furigana' ? 'on' : 'off'}.svg`)} css={radioStyle} />
                      <p>{t('Reservation name or guest name (Furigana)')}</p>
                    </div>
                    {inputType === 'furigana' && (
                      <div css={nameForm}>
                        <input
                          className="lastName"
                          type="text"
                          placeholder={t('Last name Furigana')}
                          name="lastNameFurigana"
                          value={lastNameFurigana}
                          onChange={onChangeValue}
                        />
                        <input
                          type="text"
                          placeholder={t('First name Furigana')}
                          name="firstNameFurigana"
                          value={firstNameFurigana}
                          onChange={onChangeValue}
                        />
                      </div>
                    )}
                  </div>
                )}
                <div className="reservationLabel">
                  <div css={radioItemStyle} onClick={() => switchButton('qr')}>
                    <img src={require(`@/static/images/radio_${inputType === 'qr' ? 'on' : 'off'}.svg`)} css={radioStyle} />
                    <p>{t('Check-in QR')}</p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <SelfCheckinFooter isDisabled={isDisabled} goToNextPage={() => !isDisabledSubmit && submit()} isNext={typeSubmit} />
      </div>
    </SelfCheckinLayout>
  )
}

const containerStyle = css({
  width: '100%',
  position: 'relative',
  backgroundColor: '#F2F2F2',
  '*': {
    fontFamily: 'Noto Sans JP',
    color: '#272727',
  },
})

const mainStyle = css({
  padding: '102px 0',
  minHeight: '100vh',

  '.subtitle': {
    textAlign: 'center',
    lineHeight: '31px',
    whiteSpace: 'pre-wrap',
    letterSpacing: '3.15px',
    fontSize: '21px',
    fontWeight: 'bold',
    marginBottom: '24px',
  },
})

const form = css({
  backgroundColor: 'white',
  maxWidth: '897px',
  borderRadius: '10px',
  margin: 'auto',

  '.reservationLabel': {
    width: '100%',
    padding: '18px 32px',
    borderBottom: '1px solid #f2f2f2',
  },
})

const radioItemStyle = css({
  display: 'flex',
  lineHeight: 'initial',

  p: {
    fontSize: '17px',
    lineHeight: '35px',
    marginLeft: '24px',
    fontWeight: 'bold',
  },

  '&:hover': {
    cursor: 'pointer',
  },
  img: {
    marginRight: 0,
    height: '35px',
    width: '35px',
  },
})

const radioStyle = css({
  width: 19,
})

const reservationTelForm = css({
  marginTop: '16px',
  marginLeft: '43px',

  input: {
    width: '100%',
    height: '61.21px',
    border: '1px solid #cccccc',
    borderRadius: '40px',
    fontSize: '21px',
    lineHeight: '31px',
    padding: '14px 35px',
    textAlign: 'left',
    '&::placeholder': {
      color: '#ccc',
    },
  },
})

const nameForm = css({
  display: 'flex',
  marginTop: '16px',
  marginLeft: '43px',

  '.lastName': {
    marginRight: '24px',
  },

  input: {
    width: '100%',
    height: '61.21px',
    border: '1px solid #cccccc',
    borderRadius: '40px',
    fontSize: '21px',
    lineHeight: '31px',
    padding: '14px 35px',
    textAlign: 'left',
    '&::placeholder': {
      color: '#ccc',
    },
  },
})
