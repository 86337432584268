import React, { useState, useEffect, useContext } from 'react'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { CheckinTabContainer } from '@/components/organisms/settings/checkin-tab-container'
import { InputField } from '@/components/molecules/input-field'
import { Controller, useForm } from 'react-hook-form'
import { getSelfCheckinCardReader, postSelfCheckinCardReader } from '@/apis/aipass'
import { CardReaderCommand, SelfCheckinCardReaderType, serialNumberStorageKey } from '@/models/self-checkin/card-reader'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { EditFooter } from '@/components/organisms/edit-footer'
import { TabContainer } from '@/components/organisms/settings/tab-container'
import { AccountContext } from '@/contexts/account'

type FormValue = {
  serialNumber: string
}
export const SelfCheckinCardReader: React.FC = () => {
  const { t } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isShowFooter, setIsShowFooter] = useState<boolean>(false)
  const [checkResult, setCheckResult] = useState<SelfCheckinCardReaderType>()
  const [checkProcessId, setCheckProcessId] = useState<string>()
  const { account } = useContext<any>(AccountContext)

  const { control, handleSubmit, reset } = useForm<FormValue>({
    defaultValues: { serialNumber: '' },
  })

  const fetchSetting = async (processId: string) => {
    const storedSerialNumber = localStorage.getItem(serialNumberStorageKey)
    if (!storedSerialNumber) {
      return
    }
    const commandResponses = await getSelfCheckinCardReader(account.employee.hotelId, storedSerialNumber, processId)
    const checkResult = commandResponses?.find(res => res.command === CardReaderCommand.CheckSerialNumber)
    checkResult && setCheckResult(checkResult)
  }

  const onSave = async (value: FormValue) => {
    try {
      setIsShowFooter(false)
      setIsLoading(true)
      setCheckResult(undefined)
      localStorage.setItem(serialNumberStorageKey, value.serialNumber)
      if (value.serialNumber) {
        setCheckResult({
          serial_number: value.serialNumber,
          command: CardReaderCommand.CheckSerialNumber,
          message: '接続確認中......',
          process_id: '',
          is_success: true,
        })
        const { processId } = await postSelfCheckinCardReader(value.serialNumber, CardReaderCommand.CheckSerialNumber)
        setCheckProcessId(processId)
      }
    } catch (e) {
      errorHandler(e)
    } finally {
      setIsLoading(false)
    }
  }

  const restoreSerialNumber = () => {
    const storedSerialNumber = localStorage.getItem(serialNumberStorageKey)
    reset({ serialNumber: storedSerialNumber || '' })
  }

  useEffect(() => {
    if (!checkProcessId) {
      return
    }
    const intervalId = setInterval(() => {
      fetchSetting(checkProcessId)
    }, 3000)
    return () => clearInterval(intervalId)
  }, [checkProcessId])

  useEffect(() => {
    restoreSerialNumber()
    const storedSerialNumber = localStorage.getItem(serialNumberStorageKey)
    if (storedSerialNumber) {
      setCheckResult({
        serial_number: storedSerialNumber,
        command: CardReaderCommand.CheckSerialNumber,
        message: '接続確認中......',
        process_id: '',
        is_success: true,
      })
      postSelfCheckinCardReader(storedSerialNumber, CardReaderCommand.CheckSerialNumber).then(({ processId }) =>
        setCheckProcessId(processId),
      )
    }
  }, [])

  const footerContent = isShowFooter ? (
    <EditFooter
      onSave={handleSubmit(onSave)}
      onCancel={() => {
        restoreSerialNumber()
        setIsShowFooter(false)
      }}
    />
  ) : undefined

  return (
    <>
      <SettingsLayout loading={isLoading} footerContent={footerContent}>
        <TabContainer tabComponent={<CheckinTabContainer currentTab="Card reader" />} isShowFooter={isShowFooter}>
          <div css={containerStyle}>
            <div style={{ width: 300 }}>
              <div className="input-label">{t('Serial number')}</div>
              <Controller
                control={control}
                name="serialNumber"
                render={({ field: { onChange, value } }) => (
                  <InputField
                    value={value}
                    handleChangeData={e => {
                      setIsShowFooter(true)
                      onChange(e)
                    }}
                  />
                )}
              />
            </div>
            {checkResult && (
              <div className={checkResult.is_success ? 'connect-success-text' : 'connect-error-text'}>{checkResult.message}</div>
            )}
          </div>
        </TabContainer>
      </SettingsLayout>
    </>
  )
}

const containerStyle = css({
  padding: 32,
  '.input-label': {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 12,
  },
  '.connect-success-text': {
    marginTop: 14,
    color: '#676767',
    fontSize: 12,
  },
  '.connect-error-text': {
    marginTop: 14,
    color: 'rgba(255,0,0,0.5)',
    fontSize: 12,
  },
})
