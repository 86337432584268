import React, { Dispatch, useContext, useEffect, useMemo, useState } from 'react'
import {
  NewSpace,
  SpaceDetail,
  TIME_LINE_WIDTH,
  convertDayJsFromString,
  getTime,
  getTimeRangeCount,
  isOverlapOtherTimes,
  rangeTime15minuteUnit,
} from '@/models/space-manager/timeline'
import { css } from '@emotion/core'
import { DatePicker } from './date-picker'
import dayjs from 'dayjs'
import { InputField } from '@/components/molecules/input-field'
import { TextareaField } from '@/components/molecules/textarea-field'
import { useTranslation } from 'react-i18next'
import { EditIcon } from '@/components/molecules/settings/icon/edit-icon'
import { groupBy } from 'lodash'
import { Button } from '@/components/atoms'
import { Select, Option } from '@/components/atoms/select'
import * as api from '@/apis/aipass'
import { SpaceReservation, UpdateSpaceReservationInput } from '@/models/space-manager'
import { errorHandler } from '@/libs/errors'
import { ApprovedStatus, ApprovedStatusType } from '@/models/reservation-approved-status'
import { ReservationType } from '@/models/reservation'
import { Link } from 'react-router-dom'
import { LocationDescriptor } from 'history'
import { LoaderContextCreator } from '@/contexts/loader'
import uuid from 'react-uuid'

export const reservedStatusColor = (approvedStatus: string) => {
  switch (approvedStatus) {
    case ApprovedStatus.Reservation:
      return '#FFFCDD'
    case ApprovedStatus.Checkin:
      return '#FEF3F0'
    case ApprovedStatus.Stay:
      return '#EFF9FF'
    case ApprovedStatus.Checkout:
      return '#F6FDF2'
    case 'preparation':
      return '#F2F2F2'
    default:
      return '#FFFCDD'
  }
}

interface PanelCardProps {
  onChangeDate: (datestring: string) => void
  setVisibleReservationModal: Dispatch<React.SetStateAction<boolean>>
  setEditMode: Dispatch<React.SetStateAction<boolean>>
  editMode: boolean
  spaces: NewSpace
  reservedSpaces: SpaceDetail[]
  changeSpace: (spaceTypeName: string, newDetails: SpaceDetail[]) => void
  editReservedSpace: SpaceReservation[]
  isDeleteExistReservation: boolean
  onCancelEdit: () => void
  onClosePanel: () => void
  removeEditReservation: (removeSpace: SpaceDetail) => void
  onSaved: () => void
  spaceLimitTimes: Record<string, { start: string; end: string }>
  fixedReservationId?: string
}

type ViewReservationType = {
  id: string
  reservationId: string
  checkinId: string
  guestName: string
  approvedStatus: ApprovedStatusType
}

export const PanelCardList: React.FC<PanelCardProps> = ({
  onChangeDate,
  onSaved,
  spaces,
  setVisibleReservationModal,
  editMode,
  setEditMode,
  changeSpace,
  reservedSpaces,
  onCancelEdit,
  onClosePanel,
  editReservedSpace,
  removeEditReservation,
  isDeleteExistReservation,
  spaceLimitTimes,
  fixedReservationId,
}) => {
  const paxList = Array.from(new Array(20)).map((v, i) => ++i)
  const [viewReservation, setViewReservation] = useState<ViewReservationType>()
  const [calenderPosition, setCalenderPosition] = useState<{ top: number; left: number } | undefined>()
  const { setIsLoading } = useContext(LoaderContextCreator())

  const { t } = useTranslation()

  const excludeOwnSpace = (otherSpaces: SpaceDetail[], ownSpace: SpaceDetail) => {
    return otherSpaces
      .filter(otherSpace => ownSpace?.date && otherSpace.date?.isSame(ownSpace.date))
      .filter(otherSpace => otherSpace.spaceIndex === ownSpace.spaceIndex && otherSpace.spaceTypeName === ownSpace.spaceTypeName)
      .filter(
        otherSpace =>
          !(otherSpace.usageTime.usageFrom === ownSpace.usageTime.usageFrom && otherSpace.usageTime.usageTo === ownSpace.usageTime.usageTo),
      )
  }

  const inUsageTime = (spaceTypeName: string, usageTime: { usageFrom: string; usageTo: string }) => {
    const limitTime = spaceLimitTimes[spaceTypeName]
    if (!limitTime?.start && !limitTime?.end) {
      return false
    }

    const newUseCount = getTimeRangeCount({ start: usageTime.usageFrom, end: usageTime.usageTo })

    if (limitTime.start) {
      const minTimeCount = getTimeRangeCount({ start: '00:00', end: limitTime.start })

      if (newUseCount < minTimeCount) {
        return true
      }
    }

    return false
  }

  const onChangeStartTime = (changedStartTime: string, space: SpaceDetail) => {
    const addSpaces = spaces[space.spaceTypeName]

    // 最小、最大時間のチェック
    const isInUsageTime = inUsageTime(space.spaceTypeName, { usageFrom: changedStartTime, usageTo: space.usageTime.usageTo })
    if (isInUsageTime) {
      return
    }

    // 他のアサインの時間と重ならないように
    const comparisonSpaces = excludeOwnSpace([...addSpaces, ...reservedSpaces], space)
    const isOverlap = isOverlapOtherTimes({
      newStartTime: convertDayJsFromString(changedStartTime),
      newEndTime: convertDayJsFromString(space.usageTime.usageTo),
      targetSpaces: comparisonSpaces,
      salesHour: space.salesHour,
    })
    if (isOverlap) {
      return
    }

    const newDetails = addSpaces?.map(detail => {
      if (detail.groupId === space.groupId) {
        const count = getTimeRangeCount({ start: changedStartTime, end: space.usageTime.usageTo })
        const { hour, minute } = getTime(changedStartTime)
        const left = hour * TIME_LINE_WIDTH + (minute / 60) * TIME_LINE_WIDTH
        const width = (count * TIME_LINE_WIDTH) / 4

        detail.style.left = left
        detail.style.width = width
        detail.usageTime.usageFrom = changedStartTime
      }

      return detail
    })

    changeSpace(space.spaceTypeName, newDetails)
  }

  const onChangeEndTime = (changedEndTime: string, space: SpaceDetail) => {
    const addSpaces = spaces[space.spaceTypeName]

    // 最小、最大時間のチェック
    const isInUsageTime = inUsageTime(space.spaceTypeName, { usageFrom: space.usageTime.usageFrom, usageTo: changedEndTime })
    if (isInUsageTime) {
      return
    }

    const comparisonSpaces = excludeOwnSpace([...addSpaces, ...reservedSpaces], space)

    const isOverlap = isOverlapOtherTimes({
      newStartTime: convertDayJsFromString(space.usageTime.usageFrom),
      newEndTime: convertDayJsFromString(changedEndTime),
      targetSpaces: comparisonSpaces,
      salesHour: space.salesHour,
    })
    if (isOverlap) {
      return
    }

    const newDetails = addSpaces?.map(detail => {
      if (detail.groupId === space.groupId) {
        const count = getTimeRangeCount({ start: space.usageTime.usageFrom, end: changedEndTime })
        const width = (count * TIME_LINE_WIDTH) / 4

        detail.style.width = width
        detail.usageTime.usageTo = changedEndTime
      }

      return detail
    })

    changeSpace(space.spaceTypeName, newDetails)
  }

  const onChangeDetailDate = (changedDate: string, space: SpaceDetail) => {
    const changeDate = dayjs(changedDate)
    const newDetails = spaces[space.spaceTypeName]?.map(detail => {
      if (detail.groupId === space.groupId) {
        detail.date = changeDate
      }

      return detail
    })

    changeSpace(space.spaceTypeName, newDetails)
    onChangeDate(changeDate.format('YYYY-MM-DD'))
  }

  const onFocusDetailDate = (e: React.ChangeEvent<HTMLDivElement>) => {
    const rect = e.currentTarget.getBoundingClientRect()
    setCalenderPosition({ top: rect.top + 20, left: rect.left - 20 })
  }

  const onChangeReservationUserName = (value: string, space: SpaceDetail) => {
    const newDetails = spaces[space.spaceTypeName]?.map(detail => {
      if (detail.groupId === space.groupId) {
        detail.reservationUserName = value
      }
      return detail
    })
    changeSpace(space.spaceTypeName, newDetails)
  }

  const onChangePax = (value: number, space: SpaceDetail) => {
    const newDetails = spaces[space.spaceTypeName]?.map(detail => {
      if (detail.groupId === space.groupId) {
        detail.pax = value
      }
      return detail
    })
    changeSpace(space.spaceTypeName, newDetails)
  }

  const onChangeAnswer = (value: string, space: SpaceDetail) => {
    const newDetails = spaces[space.spaceTypeName]?.map(detail => {
      if (detail.groupId === space.groupId) {
        detail.answer = value
      }
      return detail
    })
    changeSpace(space.spaceTypeName, newDetails)
  }

  const onSave = async () => {
    const assignSpaces: UpdateSpaceReservationInput[] = []
    Object.keys(spaces).forEach(spaceName => {
      spaces[spaceName].forEach(space => {
        const assign = {
          id: space.id,
          spaceReservationId: space.spaceReservationId,
          usageFrom: dayjs(`${space?.date?.format('YYYY-MM-DD')} ${space.usageTime.usageFrom}`).format('YYYY-MM-DD HH:mm:ss'),
          usageTo: dayjs(`${space?.date?.format('YYYY-MM-DD')} ${space.usageTime.usageTo}`).format('YYYY-MM-DD HH:mm:ss'),
          spaceId: space.spaceId,
          spaceIndex: space.spaceIndex,
          pax: space.pax,
          reservationUserName: space.reservationUserName || viewReservation?.guestName || '',
          answer: space.answer,
        }

        assignSpaces.push(assign)
      })
    })

    if (isDeleteExistReservation) {
      if (
        !window.confirm(
          t('The linked reimbursed subjects will not be changed or deleted. If you need to modify an item, please do so manually'),
        )
      ) {
        return
      }
    }

    const newAssignSpaces = assignSpaces.filter(s => !s.spaceReservationId)
    const editSpaces = assignSpaces.filter(s => s.spaceReservationId)
    const selectedReservationId = fixedReservationId || editReservedSpace[0]?.reservation.reservationId
    const isNewAssignWithNoReservation = newAssignSpaces.length && !selectedReservationId

    if (isNewAssignWithNoReservation) {
      setVisibleReservationModal(true)
      return
    }

    try {
      setIsLoading(true)
      const editSpaceByReserve = groupBy(editSpaces, 'spaceReservationId')
      for (const spaceReservationId of editReservedSpace.map(s => s.id)) {
        if (editSpaceByReserve[spaceReservationId]) {
          await api.updateSpaceReservation(spaceReservationId, { assignSpaces: editSpaceByReserve[spaceReservationId] })
        } else {
          await api.updateSpaceReservation(spaceReservationId, { assignSpaces: [] })
        }
      }
      // 更新・削除後によって在庫変動したあとに新規追加したスペースをアサイン
      if (newAssignSpaces.length) {
        await api.CreateSpaceReservation({
          reservationId: selectedReservationId,
          assignSpaces: newAssignSpaces,
        })
      }
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
      onSaved()
    }
  }

  const makeReservationDetailLocation = (reservation: ViewReservationType | undefined): LocationDescriptor => {
    if (!reservation) {
      return ''
    }

    const search = `?reservationId=${reservation.reservationId}`

    let pathState = { pathname: `/reservation/${reservation.id}`, search }
    if (reservation.approvedStatus === ApprovedStatusType.Checkin) {
      pathState = { pathname: `/checkin/${reservation.checkinId}`, search }
    }
    if (reservation.approvedStatus === ApprovedStatusType.Stay) {
      pathState = { pathname: `/stay/${reservation.checkinId}`, search }
    }
    if (reservation.approvedStatus === ApprovedStatusType.Checkout) {
      pathState = { pathname: `/checkout/${reservation.checkinId}`, search }
    }

    return pathState
  }

  const fetchReservationDetail = async reservationId => {
    if (!reservationId) {
      setViewReservation(undefined)
      return
    }
    const response = await api.fetchReservationDetail({ reservationId })
    const reservation = response.reservations as ReservationType
    setViewReservation({
      reservationId,
      id: reservation.id,
      checkinId: reservation.checkinId,
      guestName: reservation.guestName || reservation.userName || '-',
      approvedStatus: reservation.approvedStatus,
    })
  }

  useEffect(() => {
    fetchReservationDetail(editReservedSpace[0]?.reservation.reservationId || fixedReservationId)
  }, [editReservedSpace, fixedReservationId])

  const sortedEditingSpacesByReserve = useMemo(() => {
    const allEditingSpaces = Object.keys(spaces).flatMap(spaceTypeName => spaces[spaceTypeName])
    const groupedSameReserveSpace = groupBy(allEditingSpaces, item =>
      [item.spaceReservationId, item.spaceId, item.date?.format('YYYY-MM-DD HH:mm'), item.usageTime.usageTo].join('-'),
    )
    return Object.values(groupedSameReserveSpace).sort((a, b) => dayjs(a[0].date).diff(dayjs(b[0].date)))
  }, [spaces])

  return (
    <div css={controlPanelStyle}>
      <div className="panel-inner">
        <div className="panel-header">
          <div className="close">
            <img
              src={require('@/static/images/space_manager/arrow_left.svg')}
              className="icon"
              style={{ cursor: 'pointer' }}
              width={14}
              onClick={() => onClosePanel()}
            />
            <p className="reservation-name">
              {viewReservation?.reservationId ? (
                <Link to={() => makeReservationDetailLocation(viewReservation)} style={{ color: '#F2A40B' }}>
                  {viewReservation.guestName}
                </Link>
              ) : (
                '-'
              )}
            </p>
          </div>
          {!editMode && (
            <EditIcon
              onClick={() => {
                setEditMode(true)
              }}
            />
          )}
        </div>

        <div className="card-block">
          {sortedEditingSpacesByReserve.map(spaces => {
            const detail = spaces[0]
            const timeOptions = rangeTime15minuteUnit({ start: detail.salesHour.start, end: detail.salesHour.end })
            return (
              <div key={uuid()} css={panelCardStyle}>
                <div
                  className={`header ${editMode ? 'edit' : ''}`}
                  style={{ backgroundColor: reservedStatusColor(detail.reservation.approvedStatus.toString()) }}
                >
                  <div>
                    <div className="date select" onFocus={e => onFocusDetailDate(e)}>
                      <DatePicker
                        position={calenderPosition}
                        date={detail.date?.format('YYYY-MM-DD')}
                        onDateChange={dateString => onChangeDetailDate(dateString, detail)}
                      />
                    </div>
                    <div className="time-range">
                      <select
                        onChange={e => onChangeStartTime(e.currentTarget.value, detail)}
                        className="select"
                        value={detail.usageTime.usageFrom}
                      >
                        {timeOptions
                          .filter(timeOption => {
                            return convertDayJsFromString(timeOption).isBefore(convertDayJsFromString(detail.usageTime.usageTo))
                          })
                          .map(timeRange => {
                            return (
                              <option key={timeRange} value={timeRange}>
                                {timeRange}
                              </option>
                            )
                          })}
                      </select>
                      <span className="from-to">~</span>
                      <select
                        onChange={e => onChangeEndTime(e.currentTarget.value, detail)}
                        className="select"
                        value={detail.usageTime.usageTo}
                      >
                        {timeOptions
                          .filter(timeOption => {
                            return convertDayJsFromString(timeOption).isAfter(convertDayJsFromString(detail.usageTime.usageFrom))
                          })
                          .map(timeRange => {
                            return (
                              <option key={timeRange} value={timeRange}>
                                {timeRange}
                              </option>
                            )
                          })}
                      </select>
                    </div>
                  </div>
                  {editMode && (
                    <img
                      src={require('@/static/images/delete_yellow.svg')}
                      onClick={() => removeEditReservation(detail)}
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                </div>

                <p className="name">
                  <span>{detail.spaceTypeName}</span>
                  <span>{`× ${spaces.length}`}</span>
                </p>

                <div className="input-area">
                  {editMode ? (
                    <>
                      <div className="user-name">
                        <InputField
                          marginBottom={0}
                          disabled={!editReservedSpace.length && !detail.id}
                          onChange={e => onChangeReservationUserName(e.currentTarget.value, detail)}
                          value={detail.reservationUserName}
                          placeholder={t('Reservation name')}
                        />
                      </div>
                      <div className="pax">
                        <Select
                          value={detail.pax}
                          customStyle={css({ paddingBottom: 0, fontSize: 12 })}
                          onChange={e => onChangePax(Number(e), detail)}
                        >
                          {paxList.map((value, index) => (
                            <Option key={index} style={{ padding: 4, fontSize: 12 }} value={value}>
                              {`${value}${t('Persons')}`}
                            </Option>
                          ))}
                        </Select>
                      </div>
                      <div className="answer">
                        <TextareaField
                          handleChangeData={e => onChangeAnswer(e.currentTarget.value, detail)}
                          marginBottom={0}
                          value={detail.answer}
                          name="answer"
                          width="100%"
                          placeholder=""
                        />
                      </div>
                    </>
                  ) : (
                    <div className="confirm-block">
                      <p>{detail.reservationUserName}</p>
                      <p>{`${detail.pax}${t('Persons')}`}</p>
                      <p>{detail.answer}</p>
                    </div>
                  )}
                </div>
              </div>
            )
          })}
        </div>

        <div className="panel-button">
          {Boolean(editReservedSpace.length) && !editMode ? (
            <div css={{ width: '100%' }}>
              <Link css={LinkButtonStyle} to={() => makeReservationDetailLocation(viewReservation)}>
                {t('To details')}
              </Link>
            </div>
          ) : (
            <>
              <Button onClick={() => onCancelEdit()} buttonType={3} fontSize={12} width={110} height={29}>
                {t('Cancel')}
              </Button>
              <Button onClick={() => onSave()} buttonType={1} fontSize={12} width={110} height={29}>
                {t('Save')}
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const controlPanelStyle = css({
  width: 324,
  height: '100%',
  minHeight: '100%',
  position: 'absolute',
  top: 0,
  right: 0,
  background: '#fff',
  boxShadow: '0px 0px 6px #0000001A',
  zIndex: 20,
  '.panel-inner': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  '.panel-header': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: '0 0 64px',
    paddingInline: 16,
    height: 64,
    background: '#F2F2F2',
    position: 'sticky',
    top: 0,
    zIndex: 20,
  },
  '.close': {
    display: 'flex',
    '.reservation-name': {
      color: '#F2A40B',
      marginLeft: 16,
      fontSize: 16,
      fontWeight: 'bold',
    },
  },
  '.date': {
    fontSize: 12,
    fontWeight: 'bold',
  },
  '.card-block': {
    paddingInline: 16,
    overflowY: 'scroll',
  },
  '.panel-button': {
    display: 'flex',
    justifyContent: 'center',
    gap: 13,
    padding: '13px 17px',
    marginTop: 'auto',
    borderTop: '1px solid #f2f2f2',
  },
  '.SingleDatePickerInput': {
    border: 'none',
    textAlign: 'center',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    '.SingleDatePickerInput_calendarIcon': { display: 'none' },
  },
  '.SingleDatePicker_picker.SingleDatePicker_picker__directionLeft': {
    top: '32px !important',
  },
  '.SingleDatePickerInput__withBorder, .DateInput ': {
    width: 113,
  },
})

const panelCardStyle = css({
  margin: '16px auto 0',
  border: '1px solid #CCCCCC',
  borderRadius: 5,
  '.header': {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: 8,
    '.select': {
      fontSize: 11,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right center',
      backgroundSize: 8,
      backgroundColor: 'unset',
      pointerEvents: 'none',
    },
    '&.edit': {
      '.select': {
        backgroundImage: `url(${require('@/static/images/arrow_down_gray.svg')})`,
        pointerEvents: 'auto',
        paddingRight: 10,
      },
    },
  },
  '.date': {
    width: 68,
    marginBottom: -5,
    fontSize: 12,
    fontWeight: 'bold',
  },
  '.time-range': {
    fontSize: 11,
    '.from-to': {
      paddingInline: 3,
    },
  },
  '.name': {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '18px 15px',
    borderBottom: '1px solid #ccc',
    fontSize: 12,
  },
  '.input-area': {
    display: 'flex',
    gap: 12,
    flexWrap: 'wrap',
    marginBlock: 12,
    paddingInline: 16,
    '.user-name': {
      width: 160,
    },
    '.pax': {
      width: 75,
    },
    '.answer': {
      width: '100%',
      textarea: {
        marginBottom: 0,
      },
    },
  },
  select: {
    border: 'none',
    appearance: 'none',
  },
  '.SingleDatePickerInput__withBorder, .DateInput ': {
    width: 55,
  },
  '.confirm-block': {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 16,
    width: '100%',
    fontSize: 12,
    p: {
      '&:last-of-type': {
        width: '100%',
      },
    },
  },
})

const LinkButtonStyle = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#f2a40b',
  border: '1px solid #f2a40b',
  fontSize: 12,
  fontWeight: 'bold',
  marginInline: 'auto',
  width: 218,
  height: 29,
  borderRadius: 24,
  '&:hover': {
    color: '#FFF',
    border: '#F2A40B',
    background: '#F2A40B',
  },
})
